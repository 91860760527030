// project specific js -- require JS jQuery Kickstart: requirejs(['jquery'], function ($) {})

requirejs(['jquery'], function ($) {

    var headerheight = $('#header').outerHeight();  
    // var height_nav_prim = $('.nav-primary').outerHeight();       
    var height_nav_sec = $('.nav-secondary').outerHeight();   
    var newheight = headerheight + height_nav_sec - 20;


    function autoHeaderHeight () {
        // console.log(newheight);
        $('.ym-wrapper').css('margin-top', newheight);
    }

    // function stickyDistanceTop () {
    //     // console.log('Sticky Nav:' + newheight);
    //     // $('.stickynav__list.is-sticky').css('top', newheight);
    // }

    // document ready
    // ************************************************************************
    $( document ).ready(function() {   

        // top-nav: neues LI aufbauen
        var snippetNav = document.createElement('li');
        $(snippetNav).addClass('nav-primary__list-item login_cloned');
        $(snippetNav).appendTo('#nav .nav-primary__list');
        // top-nav: A ins neue LI umhängen
        $('#header .top-nav__btn--login').appendTo('#nav .nav-primary__list-item.login_cloned');
        $('.nav-primary__list-item.login_cloned > a').removeClass('top-nav__btn top-nav__btn--login').addClass('nav-primary__link');

        // header inner aufbauen
        var snippetHeader = document.createElement('div');
        $(snippetHeader).addClass('header_inner cloned');
        $(snippetHeader).appendTo('#header');

        // top-nav löschen
        $('nav.top-nav').remove();

        // logo & main nav in _inner umhängen
        $('#logo').appendTo('.header_inner.cloned');
        $('#nav').appendTo('.header_inner.cloned');



        // $(".stickynav__list:not([aria-hidden='true'])").attr("id", "stickynav");        // ID dran hängen, du bist die relevante UL
        // $("nav.module.stickynav").prepend("<div class='sticky-spacer'></div>");



        // std Teaser umbauen
        var teaserContent = $('.module-teaserlist > .module-teaser > a');
        // $(teaserContent).prependTo('.module-teaserlist .module-teaser .content');



        // std funktionen
        autoHeaderHeight();
        // stickyDistanceTop();
    });

    // window resize
    // ************************************************************************
    $( window ).resize(function() {  
        autoHeaderHeight();
        // stickyDistanceTop();

        // console.log('tirGGer');

        // klappt, das inline JS tirfft aber immer noch
        // $(".stickynav__list:not([aria-hidden='true'])").attr("id", "stickynav");
        // $("#stickynav").removeClass("stickynav__list").addClass('Jan');
    });
})